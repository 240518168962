var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-box"},[(_vm.title)?_c('div',{staticClass:"form-box__title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.editable)?_c('div',{staticClass:"form-box__edit-buttons"},[(!_vm.isEditing)?_c('button',{staticClass:"form-box__edit-btn",attrs:{"type":"button"},on:{"click":function () {
          _vm.$emit('toggleDisable', false);
          _vm.setIsEditing(!_vm.isEditing);
        }}},[_vm._v(" "+_vm._s('Редактировать')+" ")]):_vm._e(),(_vm.isEditing)?_c('button',{class:['form-box__edit-btn', { disabled: _vm.isEditBtnDisabled }],attrs:{"disabled":_vm.isEditBtnDisabled,"type":"button"},on:{"click":function () {
          _vm.$emit('saveHandler');
          _vm.$emit('toggleDisable', true);
          _vm.setIsEditing(!_vm.isEditing);
        }}},[_vm._v(" "+_vm._s('Сохранить')+" ")]):_vm._e(),(_vm.isEditing)?_c('button',{staticClass:"form-box__edit-btn",attrs:{"type":"button"},on:{"click":function () {
          _vm.setIsEditing(false);
          _vm.$emit('toggleDisable', true);
          _vm.$emit('cancelHandler');
        }}},[_vm._v(" "+_vm._s('Отмена')+" ")]):_vm._e()]):_vm._e()]):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }