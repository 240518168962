















































import Vue from "vue";

export default Vue.extend({
  name: 'FormBox',

  props: {
    title: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  methods: {
    setIsEditing(value: boolean) {
      this.isEditing = value;
    }
  },
});
