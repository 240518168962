

























import Vue from 'vue';
import { mapActions, mapState } from "vuex";
import download from "downloadjs";

import ItemDocument from "@/components/ItemDocument.vue";
import Button from "@/components/UI/Button.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Download from "@/components/UI/icon/Download.vue";
import { base64ToFile } from '@/helpers';

export default Vue.extend({
  name: 'RevokeSystemDocuments',

  components: { ItemDocument, Button, Download, IconBase },

  computed: {
    ...mapState('revokeState', ['isApplicationGenerated']),
  },

  methods: {
    ...mapActions('revokeState', ['getDocument']),

    async downloadApplication() {
      const { fileName, contentType, base64 } = await this.getDocument({documentType: 0});
      const { fileJS } = base64ToFile(fileName, contentType, base64);
      download(fileJS, fileName, contentType);
    }
  },

})
