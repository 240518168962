<template>
  <div class="proposal-form-documents document-download">
    <div class="proposal-form-documents-top">
      <div class="title">
        <span>{{ disableToEdit ? 'Загруженные документы' : 'Загрузите документы'}}</span>
      </div>
      <div v-if="!disableToEdit" class="short-descr">
        <p>Для завершения процесса отзыва ЭП необходимо прикрепить отсканированные копии следующих документов.</p>
        <p>Также можно сохранить прикрепленные документы без подписания, для этого нажмите "отложить"</p>
      </div>
    </div>
    <template>
      <form class="proposal-form-documents-body">
        <div class="files-wrapper">
          <ItemDocument
            v-for="(item, index) in Object.values(clientDocuments)"
            :key="index"
            :index="index"
            :maxFiles="item.maxFiles"
            :type="item.type"
            :img="item.img"
            :name="item.name"
            :title="item.title"
            :descr="item.descr"
            :valid="item.isValid"
            :accept="item.accept"
            :touched="item.isTouched"
            :errorsList="item.errors"
            :shouldValidate="!!item.rules"
            :disabled="false"
            :isUploadable="!disableToEdit"
            :isWatchable="item.isWatchable"
            :isFilled="!!item.files.length || item.isCollected"
            @change="(evt) => inputFileHandler({fileType: item.fileType, files: evt.target.files})"
            @click="() => watchUploadedDocument(item)"
          />
        </div>
        <div class="buttons-controls">
          <Button
            v-if="!disableToEdit"
            :disabled="!enableToCollect"
            color="purple-ghost"
            @click.prevent="collectDocuments"
            type="submit"
          >Отложить</Button>
          <Button
            v-if="!disableToEdit"
            :disabled="!enableToSign"
            @click.prevent="$modal.show('revoke-get-verifying-code-modal')"
            type="submit"
          >Подписать</Button>													  
        </div>
        <div class="signing-hint" v-if="!disableToEdit">
          Для подписания доступны только сертификаты, выпущенные АО «Аналитический центр»
        </div>
      </form>
    </template>

    <modal
      name="revoke-sign-modal"
      width="890"
      height="auto"
      :adaptive="true"
      :scrollable="true"
      :class="{
        'loaded': modalErrorClose,
        'error': !isBrowserPluginEnabled || errorMessage
      }"
      @before-close="beforeClose"
    >
      <template v-if="!isBrowserPluginEnabled">
        <div class="browser-plugin choice-signature">
          <div class="choice-signature__title">
            <span>Ошибка</span>
          </div>
          <div class="choice-signature__descr">
            <p>
              Чтобы продолжить, вам понадобится плагин
              <a
                href="https://www.cryptopro.ru/products/cades/plugin/get_2_0"
              >"КриптоПро ЭЦП Browser plug-in"</a>
              <br />и криптопровайдер
              <a
                target="_blank"
                href="https://www.cryptopro.ru/sites/default/files/private/csp/40/9963/CSPSetup.exe"
              >"КриптоПро CSP 4.0"</a>.
            </p>
          </div>
          <Button @click="$modal.hide('revoke-sign-modal')">Продолжить</Button>
        </div>
      </template>
      <template v-else>
        <template v-if="!errorMessage">
          <form v-if="certificates.length" class="choice-signature">
            <div class="choice-signature__title">
              <span>Выберите сертификат</span>
            </div>
            <div class="choice-signature__descr">
              <p>
                Мы нашли несколько сертификатов ЭП.
                <br />Уточните, каким нужно подписать <b>пакет документов</b>.
              </p>
            </div>
            <div class="choice-signature__img"></div>
            <Select
              name="signature"
              placeholder="Выберите подпись."
              :options="certificates"
              :value="currentThumbprint"
              @input="setCurrentThumbprint"
            />																																					
            <Button type="submit" :disabled="!currentThumbprint" @click.prevent="signAndUploadApplication">
              Подписать
            </Button>
          </form>

          <div v-else class="choice-signature">
            <div class="title">
              <span>Не удалось найти подписи на вашем устройстве.</span>
              <br />Для подписания доступны только действующие сертификаты, выпущенные АО «Аналитический центр»
            </div>
            <Button type="submit" @click.prevent="$modal.hide('revoke-sign-modal')">Отмена</Button>
          </div>
        </template>
        <template v-else>
          <div v-if="errorMessage" class="choice-signature">
            <div class="choice-signature__title">
              <span>Ошибка</span>
            </div>
            <div class="choice-signature__descr">
              <p>Попробуйте выбрать другую подпись.</p>
            </div>
            <div class="choice-signature-error">
              <span>{{errorMessage}}</span>
            </div>
            <Button @click="clearErrorMessage">Выбрать подпись</Button>
          </div>
          <Loader v-else class="choice-signature-loader" message="Идет отправка" />
        </template>
      </template>
    </modal>

    <modal
      name="revoke-get-verifying-code-modal"
      width="560px"
      height="auto"
      :adaptive="true"
      :scrollable="true"
    >
      <div class="revoke-get-verifying-code-modal__content">
        <h2>Идентификация личности</h2>
        <p>Для продолжения необходимо идентифицировать держателя сертификата по номеру телефона</p>
        <p>Телефон: <b>{{getPhone || 'не найден'}}</b></p>
        <Button :disabled="!getPhone" @click="() => getVerifyingCodeHandler({phone: getPhone})">{{`Отправить код по SMS`}}</Button>
        <Button @click="() => getVerifyingCodeHandler({})">{{'Клиент не может получить код по SMS'}}</Button>
        <Button @click="() => $modal.hide('revoke-get-verifying-code-modal')">{{'Отмена'}}</Button>
      </div>
    </modal>

    <ModalWithField
      name="revoke-insert-verifying-code-modal"
      width="560px"
      height="325px"
      title="Идентификация личности"
      description="Для продолжения введите код, полученный от клиента"
      field="input"
      label="Проверочный код"
      mask="9999"
      :rule="(value) => (!!value && value.length === 4)"
      @onClickOk="checkVerifyingCodeHandler"
    />

    <modal
      name="revoke-get-verifying-code-fail-modal"
      width="560px"
      height="auto"
      :adaptive="true"
      :scrollable="true"
    >
      <div class="revoke-get-verifying-code-modal__content">
        <h2>Идентификация личности не пройдена</h2>
        <p>Код не был доставлен или не прошёл проверку</p>
        <Button @click="() => {
                          $modal.hide('revoke-get-verifying-code-fail-modal')
                          $modal.show('revoke-get-verifying-code-modal');
                        }"
        >{{`Получить новый код`}}</Button>
        <Button @click="() => getVerifyingCodeHandler({})">{{`Продолжить без кода`}}</Button>
      </div>
    </modal>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapState, mapGetters } from "vuex";

  import ItemDocument from "@/components/ItemDocument.vue";
  import Button from "@/components/UI/Button.vue";
  import IconBase from "@/components/UI/IconBase.vue";
  import Download from "@/components/UI/icon/Download.vue";
  import Loader from "@/components/UI/Loader.vue";
  import Select from "@/components/UI/form/Select.vue";
  import ModalWithField from "@/components/ModalWithField.vue";
  import { base64ToFile } from '@/helpers';

  export default Vue.extend({
    name: 'RevokeClientDocuments',

    components: { ItemDocument, Button, Download, IconBase, Select, Loader, ModalWithField },

    data() {
      return {
        modalErrorClose: false,
      };
    },

    computed: {
      ...mapState('revokeState', [
        'clientDocuments',
        'verifyingCode',
      ]),
      ...mapState('cryptoState', [
        'certificates',
        'currentThumbprint',
        'errorMessage',
      ]),
      ...mapGetters('revokeState', [
        'enableToCollect',
        'enableToSign',
        'disableToEdit',
        'getPhone',
        'getEmail',
      ]),
      ...mapGetters('cryptoState', [
        'isBrowserPluginEnabled',
      ]),
    },

    methods: {
      ...mapActions('revokeState', [
        'inputFileHandler',
        'uploadDocument',
        'getDocument',
        'collectDocuments',
        'signDocuments',
        'signAndUploadApplication',
        'signAndUploadApplication',
        'getVerifyingCode',
        'skipVerifyingCode',
        'checkVerifyingCode',
      ]),
      ...mapActions('cryptoState', [
        'cryptoLoad',
        'getCertificates',
        'setCurrentThumbprint',
        'getSignedDocument',
        'clearCryptoState',
      ]),

      async openSignModal() {
        this.cryptoLoad();

        if (this.isBrowserPluginEnabled) {
          await this.getCertificates();
        }

        this.modalErrorClose = false;
        this.$modal.show('revoke-sign-modal');
      },

      beforeClose() {
        this.modalErrorClose = true;
        this.clearCryptoState();
      },

      async watchUploadedDocument(item) {
        let file;
        if (item.files.length) {
          file = item.files[0];
        } else {
          const { fileName, contentType, base64 } = await this.getDocument({documentType: item.fileType});
          const { fileJS } = base64ToFile(fileName, contentType, base64);
          file = fileJS;
        }
        window.open(URL.createObjectURL(file), '_blank');
      },

      async getVerifyingCodeHandler({phone}) {
        if (phone) {
          const { success } = await this.getVerifyingCode({phone});

          this.$modal.hide('revoke-get-verifying-code-modal');

          if (!success) {
            this.$modal.show('revoke-get-verifying-code-fail-modal');
            return;
          }

          this.$modal.show('revoke-insert-verifying-code-modal');
        } else {
          const { success } = await this.skipVerifyingCode();

          if (success) {
            this.$modal.hide('revoke-get-verifying-code-modal');
            this.$modal.hide('revoke-get-verifying-code-fail-modal');
            await this.openSignModal();
          }
        }
      },

      async checkVerifyingCodeHandler(code) {
        const { success, isValid } = await this.checkVerifyingCode(code);

        if (!success || !isValid) {
          this.$modal.show('revoke-get-verifying-code-fail-modal');
          return; 
        }

        this.openSignModal();
      },
    },
  })
</script>
<style lang='scss'>
[data-modal] {
  .v--modal-box {
    transition: box-shadow 0.25s ease;
  }

  &.error,
  &.loaded {
    .v--modal-box {
      box-shadow: 0 10px 60px -2px rgb(199, 79, 79);
    }
  }
}

.choice-signature-loader {
  min-height: 350px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ie {
  .choice-signature-loader {
    height: 350px;
  }
}

.choice-signature-error {
  margin: auto 0;
  flex-direction: column;
  color: #f5383b;
  font-size: 16px;
  & + .button {
    margin: 0;
  }
}

.choice-signature {
  height: 100%;
  min-height: 350px;
  padding: 70px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .form-item {
    width: 100%;
  }

  &__title {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
  }

  &__descr {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #4f4f4f;

    p {
      margin: 0;
    }
  }

  &__img {
    width: 450.53px;
    height: 293px;
    background: url("../assets/img/usb.png") no-repeat center;
  }

  .form-item-select {
    width: 375px;
    margin: -25px auto 0;
  }

  button {
    margin-top: 25px;
  }
}
.proposal-form-documents {
  width: 1000px;
  margin: 0 auto 30px auto;

  &-top,
  &-bottom {
    text-align: center;
    color: #000;

    .title {
      font-weight: 700;
      font-size: 26px;
      line-height: normal;
    }

    .short-descr {
      font-size: 16px;
      line-height: 25px;
      margin: 15px 0;

       p {
        margin: 0;
      }
    }
  }

  &-body {
    .files-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;

      margin-top: 50px;
      .item-document {
        width: 100%;
      }
    }

    .signing-hint {
      text-align: center;
    }

    .buttons-controls {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      flex: 1 1 100%;
      margin-bottom: 20px;

      .button {
        margin: 15px 0 0 0;

        & + .button {
          margin-left: 50px;
        }

        &.download-zip {
          margin-top: 15px;
          width: 100%;
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & + .button {
            margin-left: 0;
          }
        }
      }
    }

    button[type="submit"] {
      display: block;
      margin: 50px auto 0;
    }
  }
}
.proposal-form-documents:last-child {
  margin: 0 auto;
}

.revoke-get-verifying-code-modal__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  padding: 30px;

  h2 {
    text-align: center;
  }

  p {
    margin: 0;
    text-align: start;
  }

  p:first-of-type {
    text-align: justify;
    margin-bottom: 5px;
  }

  p:last-of-type {
    margin-bottom: 10px;
  }

  .button {
    width: 500px;
  }
}
</style>