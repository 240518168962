







































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import RevokeForm from "@/components/RevokeForm.vue";
import Button from "@/components/UI/Button.vue";
import ArrowLeft from "@/components/UI/icon/ArrowLeft.vue";
import Pencil from "@/components/UI/icon/Pencil.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Select from "@/components/UI/form/Select.vue";
import Loader from "@/components/UI/Loader.vue";
import Plus from "@/components/UI/icon/Plus.vue";
import {Tab, Tabs} from "@/components/Tabs";
import RevokeSystemDocuments from "@/components/RevokeSystemDocuments.vue";
import RevokeClientDocuments from "@/components/RevokeClientDocuments.vue";

export default Vue.extend({
  components: {
    RevokeForm,
    Button,
    ArrowLeft,
    Pencil,
    IconBase,
    Comment,
    PageLayout,
    Header,
    Footer,
    Select,
    Loader,
    Plus,
    Tab,
    Tabs,
    RevokeSystemDocuments,
    RevokeClientDocuments,
  },

  computed: {
    ...mapState("revokeState", [
      'orderId',
      'activeTab',
      'errorMessage',
      'revokeNotAllowed',
    ]),
  },

  methods: {
    ...mapActions("revokeState", [
      'setOrderId',
      'getRevokeApplication',
      'clearState',
      'setActiveTab',
      'clearState',
    ]),
  },

  mounted() {
    const { orderId } = this.$route.params;
    orderId && this.setOrderId(orderId.replace(/^[AА]/gi, ''));
  },

  destroyed() {
    this.clearState();
  },

  watch: {
    orderId() {
      this.orderId && this.getRevokeApplication();
    }
  }
});
