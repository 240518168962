























































































import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

import Form from "@/components/UI/form/Form.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Input from "@/components/UI/form/Input.vue";
import Select from "@/components/UI/form/Select.vue";
import TextArea from "@/components/UI/form/TextArea.vue";
import { FORM_SECTIONS } from '@/constants/revoke.constants';

export default Vue.extend ({
  name: "RevokeForm",

  components: {
    Form,
    FormBox,
    Input,
    TextArea,
    Select,
  },

  computed: {
    ...mapState("revokeState", [
      "orderId",
      'fields'
    ]),
    ...mapGetters("revokeState", [
      "getStatus",
      "getDateCreate",
      "getDateUpdate",
      "getDateRevoke",
      "getApplicantType",
      'getActiveFields'
    ]),

    formSections() {
      return FORM_SECTIONS;
    }
  },
})
